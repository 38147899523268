import React, {Fragment, useState} from "react";
import {
  Tooltip,
  TextSearchMarker,
} from "@vokymlak/kabinet-ui";
import moment from "moment";


export const ProjectPfhdList = (props) => {
  const {
    list,
    object,

    setModalSpecificationEdit,

    selectSpecification,
    setSelectSpecification,

    searchQuery,

    modalViewTable,
    setModalViewTable,
  } = props;

  return (
    !!list && (
      <Fragment>
        {list.map(item => (
          <tr
            className={
              "Table__tr Table__tr_padding Table__tr_border Table__tr_hover " +
              (!!selectSpecification && selectSpecification === item.uuid
                ? "Table__tr_focus"
                : "")
            }
            key={"item_" + item.uuid}
          >
            <td
              className="Table__td Table__td_border"
            >
              <Tooltip
                text={""}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {!!item.updated_at
                    ? moment(item.updated_at).format("DD.MM.YYYY")
                    : moment(item.created_at).format("DD.MM.YYYY")}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip text={""} style={{width: "100%", height: "100%"}}>
                <div className="Td__text Td__text_center">
                  {(!!item.ris_id && (
                      <TextSearchMarker
                        text={item.ris_id}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <div className="Td__text Td__text_center">
                {(!!item.count_pfhd_contracts &&
                  <div
                    className={"Collected__count Collected__count_blue"}
                    style={{
                      cursor: "pointer",
                      marginRight: 2,
                      marginLeft: 2,
                      borderRadius: 24,
                    }}
                    onClick={() => setModalViewTable(item)}
                  >
                    {item.count_pfhd_contracts}
                  </div>
                ) || "-"}
              </div>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip text={""} style={{width: "100%", height: "100%"}}>
                <div className="Td__text Td__text_center">
                  {(!!item.financing_type_name && (
                      <TextSearchMarker
                        text={item.financing_type_name}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip text={""} style={{width: "100%", height: "100%"}}>
                <div className="Td__text Td__text_center">
                  {(!!item.sub && (
                      <TextSearchMarker
                        text={item.sub}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip text={""} style={{width: "100%", height: "100%"}}>
                <div className="Td__text Td__text_center">
                  {(!!item.sindicator && (
                      <TextSearchMarker
                        text={item.sindicator}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip text={""} style={{width: "100%", height: "100%"}}>
                <div className="Td__text Td__text_center">
                  {(!!item.kfsr_code && (
                      <TextSearchMarker
                        text={item.kfsr_code}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip text={""} style={{width: "100%", height: "100%"}}>
                <div className="Td__text Td__text_center">
                  {(!!item.kcsr_code && (
                      <TextSearchMarker
                        text={item.kcsr_code}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip text={""} style={{width: "100%", height: "100%"}}>
                <div className="Td__text Td__text_center">
                  {(!!item.kvr_code && (
                      <TextSearchMarker
                        text={item.kvr_code}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip text={""} style={{width: "100%", height: "100%"}}>
                <div className="Td__text Td__text_center">
                  {(!!item.kesr_code && (
                      <TextSearchMarker
                        text={item.kesr_code}
                        search={searchQuery}
                      />
                    )) ||
                    "-"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip
                text={""}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.amount &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.amount / 100).toFixed(2)
                      )) ||
                    "0,00"}
                </div>
              </Tooltip>
            </td>


            {!!object && !!object.law && object.law.tag === "44" && (
              <td
                className="Table__td Table__td_border"
              >
                <Tooltip
                  text={""}
                  style={{width: "100%", height: "100%"}}
                >
                  <div className="Td__text Td__text_center">
                    {(!!item.sum_sgoz &&
                        new Intl.NumberFormat("ru-RU").format(
                          (item.sum_sgoz / 100).toFixed(2)
                        )) ||
                      "0,00"}
                  </div>
                </Tooltip>
              </td>
            )}

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip
                text={""}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.planned &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.planned / 100).toFixed(2)
                      )) ||
                    "0,00"}
                </div>
              </Tooltip>
            </td>

            <td
              className="Table__td Table__td_border"
            >
              <Tooltip
                text={""}
                style={{width: "100%", height: "100%"}}
              >
                <div className="Td__text Td__text_center">
                  {(!!item.balance &&
                      new Intl.NumberFormat("ru-RU").format(
                        (item.balance / 100).toFixed(2)
                      )) ||
                    "0,00"}
                </div>
              </Tooltip>
            </td>
          </tr>
        ))}
      </Fragment>
    )
  );
};
