import React, {useState} from "react";
import {Icon, Tooltip} from "@vokymlak/kabinet-ui";
import styleContent from "../../../../../styles/modules/Block.module.scss";
import ModalProjectPurchaseWayEdit from "./ModalProjectPurchaseWayEdit";

export const CalcP4Table = (props) => {
  const {
    object,
    calculations,
    setIsUpdateCalculations,
    isUpdateCalculations,
  } = props;

  const [modalEdit, setModalEdit] = useState(null);

  function getP4(calculations) {
    return (
      !!calculations.sgoz_amount && Number((calculations.sgoz_amount) * 0.1) > 50_000_000_00
        ? 50_000_000_00
        : !!calculations.sgoz_amount && Number((calculations.sgoz_amount) * 0.1) < 50_000_000_00
          ? Number((calculations.sgoz_amount) * 0.1)
          : 0
    )
  }

  return (
    !!calculations && (
      <div className={styleContent._block + " " + styleContent._block_bottom}>
        <div className={styleContent._block__title}>п.4 ч.1 ст.93</div>
        <div className={styleContent._block__content}>
          <table className="Table">
            <thead className="Thead">
            <tr className="Table__tr Table__tr_padding Table__tr_border">
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  СГОЗ
                  <Tooltip
                    text={"Сумма&nbsp;СГОЗ"}
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  п.4 - 44 (10%)
                  <Tooltip
                    text={
                      "10%&nbsp;от&nbsp;СГОЗ,&nbsp;до&nbsp;50&nbsp;000&nbsp;000"
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  п.4 - 44 (сумма)
                  <Tooltip
                    text={
                      'Суммируется&nbsp;по&nbsp;"Сумма&nbsp;текущего&nbsp;года"&nbsp;(п.4)'
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dbdbdb",
                }}
              >
                <div className="Th__title">
                  п.4 - 44 (Переходящие)
                  <Tooltip
                    text={
                      'Редактируемое&nbsp;поле для&nbsp;лимитов&nbsp;договоров, которые&nbsp;еще не&nbsp;перерегистрировали'
                    }
                    style={{marginLeft: 4, height: 20}}
                  >
                    <Icon name={"custom-edit"} color={"#202020"} size={20}/>
                  </Tooltip>
                </div>
              </th>
              {/*<th*/}
              {/*  className="Table__th Table__th_border"*/}
              {/*  style={{*/}
              {/*    backgroundColor: "#fff59d",*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <div className="Th__title">*/}
              {/*    п.4 - 44 (сумма эл. магазина)*/}
              {/*    <Tooltip*/}
              {/*      text={*/}
              {/*        'Суммируется&nbsp;по&nbsp;"Сумма&nbsp;текущего&nbsp;года" (ЭМ&nbsp;п.4)'*/}
              {/*      }*/}
              {/*      style={{ marginLeft: 4, height: 16 }}*/}
              {/*    >*/}
              {/*      <Icon name={"custom-help"} color={"#202020"} size={16} />*/}
              {/*    </Tooltip>*/}
              {/*  </div>*/}
              {/*</th>*/}
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#fff59d",
                }}
              >
                <div className="Th__title">
                  п.4 - 44 (разница)
                  <Tooltip
                    text={
                      "Разница&nbsp;между&nbsp;10%&nbsp;от&nbsp;СГОЗ и&nbsp;суммой&nbsp;текущего&nbsp;года&nbsp;(п.4)"
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              className={
                "Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
              }
            >
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.sgoz_amount &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(calculations.sgoz_amount / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!getP4(calculations) &&
                      new Intl.NumberFormat("ru-RU").format((getP4(calculations) / 100).toFixed(2)))
                    || "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.p4_starting_price &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(
                          (calculations.p4_starting_price) /
                          100
                        ).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#dbdbdb50",
                  cursor: "pointer"
                }}
                onClick={() => setModalEdit({
                  project: object,
                  purchaseWay: calculations.p4_purchase_way,
                  amount: calculations.p4_transition_price
                })}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.p4_transition_price &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(calculations.p4_transition_price / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#fff59d50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!getP4(calculations) &&
                    new Intl.NumberFormat("ru-RU").format(
                      ((
                        getP4(calculations)
                        - calculations.p4_starting_price
                        - calculations.p4_transition_price
                      ) / 100).toFixed(2)
                    )) || "-"}
                </div>
              </td>
            </tr>
            </tbody>
          </table>

          <div style={{
            marginTop: 8,
            fontSize: 14,
          }}>
            <p>
              <b>СГОЗ</b> - сумма СГОЗ
            </p>
            <p>
              <b>п.4 - 44 (10%)</b> - 10% от СГОЗ, до 50&nbsp;000&nbsp;000
            </p>
            <p>
              <b>п.4 - 44 (сумма)</b> - суммируется по "Сумма&nbsp;текущего&nbsp;года" (п.4)
            </p>
            <p>
              <b>п.4 - 44 (разница)</b> - разница между 10% от СГОЗ и суммой текущего года (п.4)
            </p>
          </div>
        </div>

        {!!modalEdit && (
          <ModalProjectPurchaseWayEdit
            project={modalEdit.project}
            purchaseWay={modalEdit.purchaseWay}
            amount={modalEdit.amount}
            successfulRequest={() => (
              setIsUpdateCalculations(!isUpdateCalculations)
            )}
            handleClose={() => setModalEdit(null)}
          />
        )}
      </div>
    )
  );
};
