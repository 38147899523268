import React from "react";
import {Icon, Modal,} from "@vokymlak/kabinet-ui";
import {NavLink} from "react-router-dom";

function ModalProjectPfhdViewTable(props) {
  const {
    object
  } = props;

  return (
    !!object &&
    <Modal handleClose={() => props.handleClose()}
           contentStyle={{
             width: 740
           }}>
      <div className="Form">
        <div className="Form__title">
          {(!!object.ris_id && object.ris_id) || "-"}
          <div className="Text__signature">ПФХД</div>
        </div>

        <div className="Form__field_last">
          <table className="Table">
            <thead className="Thead">
            <tr className="Table__tr Table__tr_padding Table__tr_border">
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title" style={{height: 16}}>
                  <Icon
                    name={"custom-circle-arrow-right"}
                    size={16}
                    color={"#20202050"}
                  />
                </div>
              </th>

              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Номер договора</div>
              </th>

              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Реестровый номер</div>
              </th>

              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dfdfdf",
                }}
              >
                <div className="Th__title">Предмет договора</div>
              </th>
            </tr>
            </thead>
            <tbody>

            {object.pfhd_contracts.map(item => (!!item.contract &&
              <tr
                className={
                  "Table__tr Table__tr_padding Table__tr_border"
                }
                key={"item_" + item.uuid}
              >
                <td
                  className="Table__td Table__td_border"
                >
                  <NavLink to={"/contract/" + item.contract.uuid + '/specifications'} target={"_black"}>
                    <div
                      className="Td__text Td__text_center"
                      style={{height: 20, cursor: "pointer"}}
                    >
                      <Icon
                        name={"custom-circle-arrow-right"}
                        size={20}
                        color={"#202020"}
                      />
                    </div>
                  </NavLink>
                </td>

                <td
                  className="Table__td Table__td_border"
                >
                  <div className="Td__text Td__text_center">
                    {(!!item.contract.contract_number &&
                      item.contract.contract_number) || "-"}
                  </div>
                </td>
                <td
                  className="Table__td Table__td_border"
                >
                  <div className="Td__text Td__text_center">
                    {(!!item.contract.registry_number &&
                      item.contract.registry_number) || "-"}
                  </div>
                </td>
                <td
                  className="Table__td Table__td_border"
                >
                  <div className="Td__text Td__text_center">
                    {(!!item.contract.subject &&
                      item.contract.subject) || "-"}
                  </div>
                </td>
              </tr>
            ))}

            </tbody>
          </table>
        </div>


        <div className="Form__actions Form__actions_center">
          <div className="Action__link" onClick={() => props.handleClose()}>
            Закрыть
          </div>
        </div>

      </div>
    </Modal>
  );
}

export default ModalProjectPfhdViewTable;
