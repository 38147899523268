import React, {useState} from "react";
import {Icon, Tooltip} from "@vokymlak/kabinet-ui";
import styleContent from "../../../../../styles/modules/Block.module.scss";
import ModalProjectAmountEdit from "./ModalProjectAmountEdit";

export const CalcEShopTable = (props) => {
  const {
    object,
    calculations,
    setIsUpdateCalculations,
    isUpdateCalculations,
  } = props;

  const [modalEdit, setModalEdit] = useState(null);

  return (
    !!calculations && (
      <div className={styleContent._block + " " + styleContent._block_bottom}>
        <div className={styleContent._block__title}>Расчет эл. магазина</div>
        <div className={styleContent._block__content}>
          <table className="Table">
            <thead className="Thead">
            <tr className="Table__tr Table__tr_padding Table__tr_border">
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#bbdefb",
                }}
              >
                <div className="Th__title">
                  Цена договора
                  <Tooltip
                    text={
                      'Суммируется&nbsp;по&nbsp;"Цена&nbsp;договора"&nbsp;(ЭМ)'
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#bbdefb",
                }}
              >
                <div className="Th__title">
                  Сумма начатых процедур
                  <Tooltip
                    text={
                      'Суммируется&nbsp;по&nbsp;"Начальная&nbsp;цена"&nbsp;(ЭМ)'
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#dbdbdb",
                }}
              >
                <div className="Th__title">
                  План
                  <Tooltip
                    text={
                      'Редактируемое&nbsp;поле (указывается&nbsp;плановая&nbsp;сумма&nbsp;текущего&nbsp;года)'
                    }
                    style={{marginLeft: 4, height: 20}}
                  >
                    <Icon name={"custom-edit"} color={"#202020"} size={20}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#bbdefb",
                }}
              >
                <div className="Th__title">
                  Сумма текущего года
                  <Tooltip
                    text={
                      'Суммируется&nbsp;по&nbsp;"Сумма&nbsp;текущего&nbsp;года", при&nbsp;отсутствии&nbsp;используется&nbsp;"Начальная&nbsp;цена"&nbsp;(ЭМ)'
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
              <th
                className="Table__th Table__th_border"
                style={{
                  backgroundColor: "#bbdefb",
                }}
              >
                <div className="Th__title">
                  Сумма экономии
                  <Tooltip
                    text={
                      'Разница&nbsp;между "Сумма&nbsp;начатых&nbsp;процедур"&nbsp;и "Сумма&nbsp;текущего&nbsp;года"&nbsp;(ЭМ)'
                    }
                    style={{marginLeft: 4, height: 16}}
                  >
                    <Icon name={"custom-help"} color={"#202020"} size={16}/>
                  </Tooltip>
                </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              className={
                "Table__tr Table__tr_padding Table__tr_border Table__tr_hover"
              }
            >
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#bbdefb50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.amount_e_shop &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(calculations.amount_e_shop / 100).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#bbdefb50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.starting_price_e_shop &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(
                          calculations.starting_price_e_shop / 100
                        ).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#dbdbdb50",
                  cursor: "pointer"
                }}
                onClick={() => setModalEdit({
                  project: object,
                  amountType: 'E_SHOP_PLAN',
                  amount: calculations.e_shop_plan_amount
                })}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.e_shop_plan_amount &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(
                          calculations.e_shop_plan_amount / 100
                        ).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#bbdefb50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.amount_current_year_e_shop &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(
                          calculations.amount_current_year_e_shop / 100
                        ).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
              <td
                className="Table__td Table__td_border"
                style={{
                  backgroundColor: "#bbdefb50",
                }}
              >
                <div className="Td__text Td__text_center">
                  {(!!calculations.starting_price_e_shop && !!calculations.amount_current_year_e_shop &&
                      new Intl.NumberFormat("ru-RU").format(
                        Number(
                          (calculations.starting_price_e_shop - calculations.amount_current_year_e_shop) / 100
                        ).toFixed(2)
                      )) ||
                    "-"}
                </div>
              </td>
            </tr>
            </tbody>
          </table>

          <div style={{
            marginTop: 8,
            fontSize: 14,
          }}>
            <p>
              <b>Цена договора</b> - суммируется по "Цена&nbsp;договора" (ЭМ)
            </p>
            <p>
              <b>Сумма начатых процедур</b> - суммируется по "Начальная&nbsp;цена" (ЭМ)
            </p>
            <p>
              <b>Сумма текущего года</b> - суммируется по "Сумма&nbsp;текущего&nbsp;года", при отсутствии используется
              "Начальная&nbsp;цена" (ЭМ)
            </p>
            <p>
              <b>Сумма экономии</b> - Разница между "Сумма&nbsp;начатых&nbsp;процедур" и
              "Сумма&nbsp;текущего&nbsp;года" (ЭМ)
            </p>
          </div>
        </div>

        {!!modalEdit && (
          <ModalProjectAmountEdit
            project={modalEdit.project}
            amountType={modalEdit.amountType}
            amount={modalEdit.amount}
            successfulRequest={() => (
              setIsUpdateCalculations(!isUpdateCalculations)
            )}
            handleClose={() => setModalEdit(null)}
          />
        )}
      </div>
    )
  );
};
